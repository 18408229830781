import React from "react"

const Values = () => {
  return (
    <section className="pt-8 pt-md-11 pb-5 bg-gray-200">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h1>
              Prime support. <br /> <span className="text-success"> Always there for you.</span>
            </h1>

            <p className="font-size-lg text-gray-700 mb-7 mb-md-9">Values we live by:</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card card-border border-success shadow-lg mb-6 mb-md-8">
              <div className="card-body text-center">
                <h3 className="font-weight-bold pt-1"> Be 100% reliable.</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card card-border border-success shadow-lg mb-6 mb-md-8">
              <div className="card-body text-center">
                <h3 className="font-weight-bold pt-1"> Be fast to respond.</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card card-border border-success shadow-lg mb-6 mb-md-8">
              <div className="card-body text-center">
                <h3 className="font-weight-bold pt-1"> Solve the problem.</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card card-border border-success shadow-lg mb-6 mb-md-8">
              <div className="card-body text-center">
                <h3 className="font-weight-bold pt-1"> Deliver Wow!</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card card-border border-success shadow-lg mb-6 mb-md-8">
              <div className="card-body text-center">
                <h3 className="font-weight-bold pt-1"> Stay humble.</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="card card-border border-success shadow-lg mb-6 mb-md-8">
              <div className="card-body text-center">
                <h3 className="font-weight-bold pt-1"> Have fun ✌️</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Values
