import React from "react"
import RowOne from "../rows/RowOne"
import RowThree from "../rows/RowThree"
import RowTwo from "../rows/RowTwo"

const FeatureList = () => {
  return (
    <section className="pt-8 pt-md-11 bg-gradient-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center">
            <h1>Features</h1>

            <p className="font-size-lg text-gray-700 mb-7 mb-md-9">
              Built to make your life <span className="text-primary">easy</span>.
            </p>
          </div>
        </div>
        <RowThree />
        <RowTwo />
        <RowOne />
      </div>
    </section>
  )
}

export default FeatureList
