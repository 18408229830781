import React from "react"
import { Container } from "react-bootstrap"
import One from "../assets/images/customers/01.png"
import Two from "../assets/images/customers/02.png"
import Three from "../assets/images/customers/03.png"
import Four from "../assets/images/customers/04.png"
import Five from "../assets/images/customers/05.png"
import Six from "../assets/images/customers/06.png"
import FeatureList from "../components/campaigns/FeatureList"
import Values from "../components/campaigns/Values"
import SetupLumis from "../components/home/SetupLumis/SetupLumis"
import Layout from "../components/Layout"

const Festivals = () => (
  <Layout>
    <div>
      <section className="py-8 py-md-11 border-bottom">
        <Container>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h2 className="display-3">
                Your ticket sale <br /> is our <strong>top priority.</strong>
              </h2>

              <p className="lead text-muted mb-7 mb-md-9">Just ask any of our customers:</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <div className="form-row">
                <div className="col-4">
                  <img src={One} className="img-fluid rounded shadow-lg" alt="..." />
                </div>
                <div className="col-3">
                  <img src={Two} className="img-fluid rounded shadow-lg mb-4" alt="..." />

                  <img src={Three} className="img-fluid rounded shadow-lg mt-1" alt="..." />
                </div>
                <div className="col-5">
                  <div className="form-row mb-4">
                    <div className="col-5">
                      <img src={Four} className="img-fluid rounded shadow-lg" alt="..." />
                    </div>
                    <div className="col-7">
                      <img src={Five} className="img-fluid rounded shadow-lg" alt="..." />
                    </div>
                  </div>

                  <img src={Six} className="img-fluid rounded shadow-lg mt-1" alt="..." />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Values />
      <SetupLumis />
      <FeatureList />
    </div>
  </Layout>
)

export default Festivals
