import React from "react"
import feature1 from "../../../../assets/images/features/feature4.png"
import feature2 from "../../../../assets/images/features/feature5.png"
import feature3 from "../../../../assets/images/features/feature6.png"

const RowTwo = () => {
  return (
    <div className="row mb-6">
      <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up">
        <div className="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
          <img src={feature1} alt="..." className="card-img-top" />

          <div className="position-relative">
            <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <div className="card-body position-relative">
            <div className="position-relative text-right mt-n8 mr-n4 mb-3">
              <span className="badge badge-pill badge-white">
                <span className="h6 text-uppercase"></span>
              </span>
            </div>
            <h3>Full Data Export</h3>

            <p className="text-muted">
              Easily export lists of tickets, customers, emails, anything...
            </p>

            <a href="#!" className="font-weight-bold text-decoration-none text-warning d-none">
              Watch example
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
        <div className="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
          <img src={feature2} alt="..." className="card-img-top" />

          <div className="position-relative">
            <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <div className="card-body position-relative">
            <div className="position-relative text-right mt-n8 mr-n4 mb-3">
              <span className="badge badge-pill badge-white">
                <span className="h6 text-uppercase"></span>
              </span>
            </div>
            <h3>Flexible Payment </h3>

            <p className="text-muted">Get payments via bank transfer or any other method.</p>

            <a href="#!" className="font-weight-bold text-decoration-none text-success d-none">
              Watch example
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
        <div className="card shadow-light-lg lift lift-lg">
          <img src={feature3} alt="..." className="card-img-top" />

          <div className="position-relative">
            <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <div className="card-body position-relative">
            <div className="position-relative text-right mt-n8 mr-n4 mb-3">
              <span className="badge badge-pill badge-white">
                <span className="h6 text-uppercase"></span>
              </span>
            </div>
            <h3>Instant Payout</h3>

            <p className="text-muted">
              Get paid out the money from your ticket sales daily or weekly.
            </p>

            <a href="#!" className="font-weight-bold text-decoration-none text-primary d-none">
              Watch example
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RowTwo
