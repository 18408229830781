import React from "react"
import feature1 from "../../../../assets/images/features/feature1.png"
import feature2 from "../../../../assets/images/features/feature2.png"
import feature3 from "../../../../assets/images/features/feature3.png"

const RowOne = () => {
  return (
    <div className="row mb-6">
      <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up">
        <div className="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
          <img src={feature1} alt="..." className="card-img-top" />

          <div className="position-relative">
            <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <div className="card-body position-relative">
            <div className="position-relative text-right mt-n8 mr-n4 mb-3">
              <span className="badge badge-pill badge-danger">
                <span className="h6 text-uppercase">new</span>
              </span>
            </div>
            <h3>Sales Prediction</h3>

            <p className="text-muted">
              We can predict if you sell out from the first 50 ticket sales.
            </p>

            <a href="#!" className="font-weight-bold text-decoration-none text-warning d-none">
              Watch example
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
        <div className="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
          <img src={feature2} alt="..." className="card-img-top" />

          <div className="position-relative">
            <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <div className="card-body position-relative">
            <div className="position-relative text-right mt-n8 mr-n4 mb-3">
              <span className="badge badge-pill badge-danger">
                <span className="h6 text-uppercase">new</span>
              </span>
            </div>
            <h3>Automated Surveys</h3>

            <p className="text-muted">We send and evaluate NPS score & user feedback for you.</p>

            <a href="#!" className="font-weight-bold text-decoration-none text-success d-none">
              Watch example
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
        <div className="card shadow-light-lg lift lift-lg">
          <img src={feature3} alt="..." className="card-img-top" />

          <div className="position-relative">
            <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <div className="card-body position-relative">
            <div className="position-relative text-right mt-n8 mr-n4 mb-3">
              <span className="badge badge-pill badge-white">
                <span className="h6 text-uppercase"></span>
              </span>
            </div>
            <h3>GDPR Email Capture</h3>

            <p className="text-muted">We collect 3X more emails with GDPR consent.</p>

            <a href="#!" className="font-weight-bold text-decoration-none text-primary d-none">
              Watch example
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RowOne
